.richText {
  width: 100%;

  :global(.text-align-center) {
    text-align: center;
  }

  :global(.text-align-right) {
    text-align: right;
  }
}

.text {
  // this is needed, because the negative margins in the text tags create strange layout issues
  color: inherit;
}
