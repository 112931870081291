.wrapper {
  padding: 6px 0;
  display: flex;
  justify-content: center;
}

.container {
  width: calc(100% - 12px);
  margin: 0 auto;
}

.card {
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
}

:global(body.editor-app) {
  background-color: transparent !important;
}
