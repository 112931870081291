.slotContentWrapperOuter {
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  position: relative;
}

.slotContentWrapperInner {
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.verticalAlignTop {
  justify-content: flex-start;
}

.verticalAlignCenter {
  justify-content: center;
}

.verticalAlignBottom {
  justify-content: flex-end;
}
