.border {
  position: absolute;
}

.top {
  top: 0;
}

.right {
  top: 0;
  right: 0;
  bottom: 0;
}

.bottom {
  bottom: 0;
}

.left {
  top: 0;
  bottom: 0;
  left: 0;
}

.fullWidth {
  right: 0;
  left: 0;
}

.splitLeft {
  left: 0;
}

.splitRight {
  right: 0;
}
