@import '../../../../../../styles/functions.module';

.entryWrapper,
.contentWrapper {
  display: flex;
  width: 100%;
}

.alignLeft {
  .name {
    flex-grow: 1;
  }

  .descriptionWrapper {
    display: flex;

    .description {
      margin-right: unit-mm(2);
    }

    .ghostPrice {
      visibility: hidden;
    }
  }

  .contentWrapper {
    flex-direction: row;
  }
  .nameAndPriceWrapper {
    display: flex;
    align-items: flex-end;
  }
  .nameWrapper {
    margin-right: unit-mm(2);
    flex-grow: 1;
    display: flex;
  }

  .textWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}
.alignCenter {
  .contentWrapper {
    flex-direction: column;
  }
}
