@import '../../../../styles/functions.module.scss';
@import '../../../../styles/mixins.module.scss';
@import '../../../../styles/constants.module';

.container {
  @include absoluteFullArea();
  overflow: hidden;
  z-index: $Z_INDEX_PAGE_BACKGROUND;
}

.background {
  position: absolute;
  top: unit-mm(-3);
  right: unit-mm(-3);
  bottom: unit-mm(-3);
  left: unit-mm(-3);
  overflow: hidden;
}

.backgroundImage {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
