.imageElement {
  display: flex;
  flex: 1;
}

.center {
  justify-content: center;
}

.right {
  justify-content: flex-end;
}

.imageWrapper {
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.imageElement .circle {
  border-radius: 50%;
}
