.inline {
  display: inline;
}

.inlineBlock {
  display: inline-block;
}

.block {
  display: block;
}
