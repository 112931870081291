@import '../../../../styles/functions.module';

.row {
  flex-direction: row;
}

.col {
  flex-direction: column;
}

.grow {
  flex-grow: 1;
}
