@import '../../../../styles/constants.module';

.wrapper {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $Z_INDEX_PAGE_NUMBER;
  pointer-events: none;
}

.number {
  display: inline;
  pointer-events: auto;
}
