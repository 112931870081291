.centered {
  display: flex;
  justify-content: center;
}

.bar {
  overflow: hidden;
  width: auto;
  display: inline-block;
  max-width: 100%;
}

.text {
  position: relative;
  top: -10000px;
}
