@import '../../../../styles/constants.module';

.wrapper {
  position: absolute;
  z-index: $Z_INDEX_ADVERTISEMENT;
  display: block;
  box-sizing: content-box;

  img {
    display: flex;
  }
}
