.wordsWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  white-space: break-spaces;
  align-items: flex-end;
}

.center {
  .wordsWrapper {
    justify-content: center;
  }
}

.showDottedLine {
  .wordsWrapper:after {
    content: '';
    height: 0;
    border-bottom-style: dotted;
    border-bottom-color: inherit;
    flex-grow: 1;
    margin-bottom: 3px;
  }

  &.tiny .wordsWrapper:after {
    border-bottom-width: 0.5px;
  }

  &.normal .wordsWrapper:after {
    border-bottom-width: 2px;
  }
}
