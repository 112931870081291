.card {
  width: 100%;

  // reset material ui css baseline reset
  letter-spacing: normal;
  -webkit-font-smoothing: auto;

  // ensure browser don't adjust the font-size
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}
