.page {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  background-color: #f8f8f8;
  min-height: 100vh;
  position: relative;
  padding: 80px 20px;
}

.navigation {
  height: 40px;
  background-color: #cfcfcf;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  background-color: #cfcfcf;
}
