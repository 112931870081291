@import 'styles/constants.module';

html {
  font-size: 62.5%; /* 62.5% of 16px = 10px */
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: scroll;
  color: $BODY_TEXT !important;
  background: transparent !important;
}
.exportSomething {
  color: inherit;
}

a,
a:visited,
a:active,
input,
textarea {
  color: $BODY_TEXT;
}
