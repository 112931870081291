.footer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}

.logo {
  display: flex;
}

.text {
  font-size: 12px;
  margin-right: 5px;
  line-height: 1;
}
.white {
  color: white;
}
